<template>
  <div class="addenterprise">
    <div class="store_table">
      <div
        class="store_table_item"
        v-for="(item, ii) in orderType"
        :key="ii"
        @click="storeClick(item.id)"
        :class="item.id === typeIndex ? 'active' : ''"
      >
        <span>{{ item.code }}</span>
      </div>
    </div>
    <div class="essential_information">
      <h4 v-if="typeIndex == 1">基本信息</h4>
      <div class="form_essential">
        <el-form ref="form" :model="form" :rules="rules" label-width="130px">
          <div v-if="typeIndex == 1">
            <el-form-item label="企业logo:" prop="companyLogo">
              <img-big-upload
                :imageShow.sync="form.companyLogo"
                :addTitleShow="false"
                @removeImg="removeImgDians"
                @uploadOneImgShow="uploadOneImgShowDians"
              />
            </el-form-item>
            <el-form-item label="企业名称:" prop="companyName">
              <el-input v-model="form.companyName" placeholder="请输入企业名称" />
            </el-form-item>
            <el-form-item label="企业全称:" prop="companyFullName">
              <el-input v-model="form.companyFullName" placeholder="请输入企业全称" />
            </el-form-item>
            <el-form-item label="企业法人:" prop="legalPerson">
              <el-input v-model="form.legalPerson" placeholder="请输入企业法人" />
            </el-form-item>
            <el-form-item label="企业电话:" prop="companyPhone">
              <el-input v-model="form.companyPhone" placeholder="请输入企业电话" />
            </el-form-item>
            <el-form-item label="企业官网:" prop="officialWebsite">
              <el-input v-model="form.officialWebsite" placeholder="请输入企业官网" />
            </el-form-item>
            <el-form-item label="企业邮箱:" prop="companyEmail">
              <el-input v-model="form.companyEmail" placeholder="请输入企业邮箱" />
            </el-form-item>
            <el-form-item label="企业类型:" prop="enterpriseType">
              <el-input v-model="form.enterpriseType" placeholder="请输入企业类型" />
            </el-form-item>
            <el-form-item label="所属行业:" prop="industry">
              <el-input v-model="form.industry" placeholder="请输入所属行业" />
            </el-form-item>
            <el-form-item label="所在地区:" prop="city">
              <div class="select_inner">
                <el-select v-model="form.province" placeholder="请选择省份" @change="citychange">
                  <el-option
                    v-for="item in operatingList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
                <div style="display: inline-block; width: 10px"></div>
                <el-select v-model="form.city" placeholder="请选择地区" @focus="citychange">
                  <el-option
                    v-for="item in operatingLists"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-form-item>

            <el-form-item label="企业地址:" prop="enterpriseAddress">
              <el-input v-model="form.enterpriseAddress" placeholder="请输入企业地址" />
            </el-form-item>
            <el-form-item label="成立日期:" prop="establishmentDate">
              <div class="date_picker">
                <el-date-picker
                  value-format="yyyy-MM-dd"
                  v-model="form.establishmentDate"
                  type="date"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </div>
            </el-form-item>
            <el-form-item label="参保人数:" prop="insuredPersonsNumber">
              <el-input v-model="form.insuredPersonsNumber" placeholder="请输入参保人数" />
            </el-form-item>

            <el-form-item label="企业规模:" prop="enterpriseScale">
              <el-radio-group v-model="form.enterpriseScale">
                <el-radio :label="'1'">特大型</el-radio>
                <el-radio :label="'2'">大型</el-radio>
                <el-radio :label="'3'">中型</el-radio>
                <el-radio :label="'4'">小型</el-radio>
                <el-radio :label="'5'">微型</el-radio>
                <el-radio :label="'6'">其他</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="融资轮次:" prop="financingRounds">
              <el-radio-group v-model="form.financingRounds">
                <el-radio :label="'1'">未融资</el-radio>
                <el-radio :label="'2'">种子轮</el-radio>
                <el-radio :label="'3'">天使轮</el-radio>
                <el-radio :label="'4'">re-A轮</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="企业简介:" prop="enterpriseIntro">
              <el-input
                v-model="form.enterpriseIntro"
                type="textarea"
                placeholder="请输入企业简介"
              />
            </el-form-item>
            <el-form-item label="是否有展厅:" prop="exhibitionHall">
              <el-radio-group v-model="form.exhibitionHall">
                <el-radio :label="'1'">是</el-radio>
                <el-radio :label="'0'">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="企业类别:" prop="">
              <el-radio-group v-model="form.enterpriseCategory">
                <el-radio
                  v-for="item in codelist"
                  :key="item.codeId"
                  :label="item.codeName"
                ></el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="关联秘书长:" prop="secretaryGeneralId">
              <!-- <el-input v-model="form.secretaryGeneralId" placeholder="请选择关联秘书长" />
           -->
              <el-select v-model="form.secretaryGeneralId" placeholder="请选择">
                <el-option
                  v-for="(item, index) in generalist"
                  :key="index"
                  :label="item.realName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="关联商会:" prop="chamberOfCommerceId">
              <!-- <el-input v-model="form.chamberOfCommerceId" placeholder="请选择关联商会" />
            -->
              <el-select v-model="form.chamberOfCommerceId" placeholder="请选择">
                <el-option
                  v-for="(item, index) in comlist"
                  :key="index"
                  :label="item.chamberCommerceName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <div class="applets_share">
              <h4>小程序分享链接信息</h4>
              <el-form-item label="分享链接标题:" prop="shareTitle">
                <el-input v-model="form.shareTitle" placeholder="请输入分享链接标题" />
              </el-form-item>
              <el-form-item label="分享链接图片:" prop="sharePictures">
                <div class="com_upload">
                  <img-big-upload
                    :imageShow.sync="form.sharePictures"
                    :addTitleShow="false"
                    @removeImg="removeImgDian"
                    @uploadOneImgShow="uploadOneImgShowDian"
                  />
                </div>
                <div class="img_tips">
                  <span>(建议整体尺寸为630*510px，图片格式应为jpg、jpeg、png，图片应小于12MB)</span>
                </div>
              </el-form-item>
            </div>
          </div>
          <div v-if="typeIndex == 2 && id" style="padding-top: 30px">
            <el-form-item label="行业标签:" prop="">
              <el-checkbox-group v-model="codelist1">
                <el-checkbox
                  v-for="item in operaList1"
                  :label="item.codeName"
                  :key="item.codeId"
                  @change="codechange1(item.codeName, 4)"
                  >{{ item.codeName }}</el-checkbox
                >
              </el-checkbox-group>
              <div class="child_label" v-if="operaListchild1.length > 0">
                <span style="color: #7c7f8e; width: 65px; display: inline-block">二级标签:</span>
                <el-checkbox-group v-model="codechildlist1">
                  <el-checkbox
                    v-for="item in operaListchild1"
                    :label="item.codeName"
                    :key="item.codeId"
                    @change="codechange1(item.codeName, 5)"
                    >{{ item.codeName }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
              <div style="margin-top: 15px" class="child_label" v-if="child.length > 0">
                <span style="color: #7c7f8e; width: 65px; display: inline-block">三级标签:</span>
                <el-checkbox-group v-model="childcode1">
                  <el-checkbox v-for="item in child" :label="item.codeName" :key="item.codeId" >{{
                    item.codeName
                  }}</el-checkbox>
                </el-checkbox-group>
              </div>
            </el-form-item>
            <el-form-item label="战略新兴产业标签:" prop="">
              <el-checkbox-group v-model="codelist2">
                <el-checkbox
                  v-for="item in operaList2"
                  :label="item.codeName"
                  :key="item.codeId"
                  @change="codechange2(item.codeName)"
                  >{{ item.codeName }}</el-checkbox
                >
              </el-checkbox-group>
              <div class="child_label" v-if="operaListchild2.length > 0">
                <span style="color: #7c7f8e; width: 55px; display: inline-block">子标签:</span>
                <el-checkbox-group v-model="codechildlist2">
                  <el-checkbox
                    v-for="item in operaListchild2"
                    :label="item.codeName"
                    :key="item.codeId"
                    >{{ item.codeName }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
            </el-form-item>

            <el-form-item label="技术标签:" prop="">
              <el-checkbox-group v-model="codelist3">
                <el-checkbox
                  v-for="item in operaList3"
                  :label="item.codeName"
                  :key="item.codeId"
                  @change="codechange3(item.codeName)"
                  >{{ item.codeName }}</el-checkbox
                >
              </el-checkbox-group>
              <div class="child_label" v-if="operaListchild3.length > 0">
                <span style="color: #7c7f8e; width: 55px; display: inline-block">子标签:</span>
                <el-checkbox-group v-model="codechildlist3">
                  <el-checkbox
                    v-for="item in operaListchild3"
                    :label="item.codeName"
                    :key="item.codeId"
                    >{{ item.codeName }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>
    <div style="margin-top: 30px" slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm">保存</el-button>
      <el-button @click="clcans">取消</el-button>
    </div>
  </div>
</template>

<script>
import {
  addcompany,
  editcompany,
  getcompany,
  getlabel,
  addlabel,
  editlabel,
  getSecretaryGeneral,
  commercelist,
  getCode
} from '@/api/datement'

import { getByFatherIdt, getFieldList, getProvince, getCodeMasterByParenId } from '@/api/demand'

export default {
  name: 'addenterprise',
  computed: {
    id() {
      return this.$route.query.id * 1
    }
  },
  data() {
    return {
      form: {
        chamberOfCommerceId: null, //关联商会ID
        city: '', //所在地区
        companyEmail: null, //公司邮箱
        companyFullName: null, //企业全称
        companyLogo: '', //企业logo
        companyName: null, //企业名称
        companyPhone: null, //公司电话
        country: '中国', //所在国家
        createId: null,
        createTime: null,
        employees: null, //企业人数
        enterpriseAddress: null, //公司地址
        enterpriseIntro: null, //简介
        enterpriseScale: null, //企业规模
        enterpriseType: null, //企业类型
        establishedTime: null, //成立时间
        establishmentDate: null, //成立日期
        exhibitionHall: null, //是否有展厅;1是有0是没有
        financingRounds: null, //融资轮次
        id: null,
        industry: null, //	所属行业
        insuredPersonsNumber: null, //参保人数
        legalPerson: null, //法人
        officialWebsite: null, //公司官网
        operatingState: null,
        province: '', //所在省份
        secretaryGeneralId: null, //秘书长ID
        sharePictures: '', //分享链接的图片
        shareTitle: null, //分享链接的标题
        updateId: null,
        updateTime: null,
        aiTag: null, //技术标签
        industrialTag: null, //产业标签
        industryTag: null, //行业标签
        enterpriseCategory: null
      }, //表单收集

      orderType: [
        {
          id: 1,
          code: '企业资料'
        }
      ],
      //企业标签查询
      queryInfo: {
        pageNum: 1,
        pageSize: 10,
        blacklist: null, //1是冻结，0是不冻结
        companyId: null, //企业id
        labelName: null, //标签名称
        labelType: null, //标签类别
        weightProportion: null //权重占比
      },
      //企业标签
      listData: [],
      //新增标签
      companyLabel: {
        blacklist: null,
        companyId: null,
        id: null,
        labelName: null,
        labelType: '011',
        parentId: null,
        weightProportion: null
      },
      //标签类别
      radiolist: [
        {
          id: '011',
          val: '行业标签'
        },
        {
          id: '010',
          val: '战略信息产业标签'
        },
        {
          id: '012',
          val: '技术标签'
        }
      ],
      //标签条数
      total: 0,
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      //战略信息标签的字段
      operaList2: [],
      operaListchild2: [],
      codelist2: [],
      codechildlist2: [],
      //技术标签
      codechildlist3: [],
      operaList3: [],
      codelist3: [],
      operaListchild3: [],
      //行业标签
      codechildlist1: [],
      operaList1: [],
      codelist1: [],
      operaListchild1: [],
      child: [],
      childcode1: [],
      list1: [],
      listchild1: [],
      namelist2: [], //清楚二标签
      namelist3:[],//清楚三级
      listchange:[],
      //秘书长
      generalist: [],
      //商会
      comlist: [],
      //tab切换
      typeIndex: 1,
      operatingLists: [],
      operatingList: [],
      //查询标签一览
      paramcode: {
        codeType: null,
        parentId: null
      },
      //企业类别
      codelist: [],

      rules: {
        companyName: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        companyFullName: [{ required: true, message: '请输入企业全称', trigger: 'blur' }],
        legalPerson: [{ required: true, message: '请输入企业法人', trigger: 'blur' }],
        companyPhone: [
          { required: true, message: '请输入企业电话', trigger: 'blur' },
          // 这个只能验证手机号
          // { pattern:/^0{0,1}(13[0-9]|15[7-9]|153|156|18[7-9])[0-9]{8}$/, message: "请输入合法手机号", trigger: "blur" }
          {
            pattern: /((13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8})|^(0[0-9]{2,3}-)?([2-9][0-9]{0,9})(-[0-9]{1,9}){0,9}$/,
            message: '请输入合法手机号/电话号',
            trigger: 'blur'
          }
        ],

        officialWebsite: [{ required: true, message: '请输入企业官网', trigger: 'blur' }],
        companyEmail: [{ required: true, message: '请输入企业邮箱', trigger: 'blur' }],
        enterpriseType: [{ required: true, message: '请输入企业类型', trigger: 'blur' }],
        industry: [{ required: true, message: '请输入所属行业', trigger: 'blur' }],
        commodityName: [{ required: true, message: '请输入所在地区', trigger: 'blur' }],
        enterpriseAddress: [{ required: true, message: '请输入企业地址', trigger: 'blur' }],
        establishmentDate: [{ required: true, message: '请添加成立日期', trigger: 'change' }],
        enterpriseScale: [{ required: true, message: '请输入企业规模', trigger: 'blur' }],
        financingRounds: [{ required: true, message: '请勾选融资轮次', trigger: 'change' }],
        enterpriseIntro: [{ required: true, message: '请输入企业简介', trigger: 'blur' }],
        city: [{ required: true, message: '请选择地区', trigger: 'change' }],
        insuredPersonsNumber: [{ required: true, message: '请输入参保人数', trigger: 'blur' }],
        companyLogo: [{ required: true, message: '请上传logo', trigger: 'change' }]
        // secretaryGeneralId: [{ required: true, message: '请添加关联秘书长', trigger: 'change' }],
        // chamberOfCommerceId: [{ required: true, message: '请添加关联商会', trigger: 'change' }],
        // shareTitle: [{ required: true, message: '请输入分享链接标题', trigger: 'blur' }],
        // sharePictures: [{ required: true, message: '请上传分享链接图片', trigger: 'change' }]
      }
    }
  },
  created() {
    if (this.id) {
      this.getFieldList3('0')
      this.getFieldList2('0')
      this.getFieldList1('0')
      setTimeout(() => {
        this.getcompany()
      }, 1000)
    }
    this.incity()
    this.getSecretaryGeneral()
    this.commercelist()
    this.getCode()
  },
  methods: {
    //查询企业信息回显
    async getcompany() {
      const res = await getcompany(this.id)
      if (res.data.resultCode == 200) {
        this.form = res.data.data
        if (this.form.aiTag != null && this.form.aiTag != '') {
          this.form.aiTag = this.form.aiTag.split(',')
          //技术标签

          this.form.aiTag.forEach((el) => {
            //父标签

            this.operaList3.forEach((o) => {
              if (el == o.codeName) {
                this.codelist3.push(el)
              }
            })
          })

          this.codelist3.forEach((el) => {
            this.operaList3.forEach((o) => {
              if (el == o.codeName) {
                this.getFieldList3(o.codeId, 2)
              }
            })
          })
        }
        //战略产业标签
        if (this.form.industrialTag != null && this.form.industrialTag != '') {
          this.form.industrialTag = this.form.industrialTag.split(',')
          this.form.industrialTag.forEach((el) => {
            //父标签
            this.operaList2.forEach((o) => {
              if (el == o.codeName) {
                this.codelist2.push(el)
              }
            })
          })

          this.codelist2.forEach((el) => {
            this.operaList2.forEach((o) => {
              if (el == o.codeName) {
                this.getFieldList2(o.codeId, 2)
              }
            })
          })
        }
        //行业标签
        if (this.form.industryTag != null && this.form.industryTag != '') {
          this.form.industryTag = this.form.industryTag.split(',')
          this.form.industryTag.forEach((el) => {
            //父标签
            this.operaList1.forEach((o) => {
              if (el == o.codeName) {
                this.codelist1.push(el)
              }
            })
            this.codelist1 = [...new Set(this.codelist1)];

          })

          this.codelist1.forEach((el) => {
            this.operaList1.forEach((o) => {
              if (el == o.codeName) {
                this.getFieldList1(o.codeId, 2)
              }
            })
          })
        }
        this.orderType.push({
          id: 2,
          code: '企业标签'
        })
      }
    },
    //企业类别
    async getCode() {
      const res = await getCode({ code: '013' })
      if (res.data.resultCode == 200) {
        this.codelist = res.data.data
      }
    },
    //战略标签chang
    codechange2(codename) {
      console.log(codename)
      if (this.codelist2.length == 0) {
        this.codechildlist2 = []
        this.getFieldList2('0')
      } else {
        var s = this.codelist2.indexOf(codename)
        if (s == -1) {
          this.operaList2.forEach((o) => {
            if (codename == o.codeName) {
              this.getFieldList2(o.codeId, 1)
            }
          })
        } else {
          this.operaList2.forEach((o) => {
            if (codename == o.codeName) {
              this.getFieldList2(o.codeId, 2)
            }
          })
        }
      }
    },
    //战略新兴产业标签
    async getFieldList2(id, index) {
      this.paramcode.codeType = '010'
      this.paramcode.parentId = id

      const res = await getCodeMasterByParenId(this.paramcode)
      if (res.data.resultCode == 200) {
        if (this.paramcode.parentId == '0') {
          this.operaList2 = res.data.data
          this.operaListchild2 = []
        } else {
          if (index == 2) {
            this.operaListchild2 = this.operaListchild2.concat(res.data.data)
          } else if (index == 1) {
            let namelist = this.operaListchild2.filter(
              (item) => !res.data.data.some((ele) => ele.codeName == item.codeName)
            )

            this.operaListchild2 = namelist
          }
        }
      }
    },
    //技术标签chang
    codechange3(codename) {
      if (this.codelist3.length == 0) {
        this.codechildlist3 = []
        this.getFieldList3('0')
      } else {
        var s = this.codelist3.indexOf(codename)
        if (s == -1) {
          this.operaList3.forEach((o) => {
            if (codename == o.codeName) {
              this.getFieldList3(o.codeId, 1)
            }
          })
        } else {
          this.operaList3.forEach((o) => {
            if (codename == o.codeName) {
              this.getFieldList3(o.codeId, 2)
            }
          })
        }
      }
    },
    //技术标签标签
    async getFieldList3(id, index) {
      this.paramcode.codeType = '012'
      this.paramcode.parentId = id
      const res = await getCodeMasterByParenId(this.paramcode)
      if (res.data.resultCode == 200) {
        if (this.paramcode.parentId == '0') {
          this.operaList3 = res.data.data
          this.operaListchild3 = []
        } else {
          if (index == 2) {
            this.operaListchild3 = this.operaListchild3.concat(res.data.data)
          } else if (index == 1) {
            let namelist = this.operaListchild3.filter(
              (item) => !res.data.data.some((ele) => ele.codeName == item.codeName)
            )
            this.operaListchild3 = namelist
          }
        }
      }
    },
    //行业标签chang
    codechange1(codename, index) {
      if (this.codelist1.length == 0) {
        this.child = []
        this.codechildlist1 = []
        this.childcode1 = []
        this.getFieldList1('0')
      } else {
        if (codename == '' && index == 3) {
          this.codechildlist1.forEach((el) => {
            this.operaListchild1.forEach((o) => {
              if (el == o.codeName) {
                this.getFieldcode(o.codeId, 9)
              }
            })
          })
        }

        if (index == 4) {
          var s = this.codelist1.indexOf(codename)
          if (s == -1) {
            this.operaList1.forEach((o) => {
              if (codename == o.codeName) {
                this.getFieldList1(o.codeId, 1)
              }
            })
          } else {
            this.operaList1.forEach((o) => {
              if (codename == o.codeName) {
                this.getFieldList1(o.codeId, 2)
              }
            })
          }
        } else if (index == 5) {
          var s1 = this.codechildlist1.indexOf(codename)
          if (s1 == -1) {
            this.operaListchild1.forEach((o) => {
              if (codename == o.codeName) {
                this.getFieldcode(o.codeId, 1)
              }
            })
          } else {
            this.operaListchild1.forEach((o) => {
              if (codename == o.codeName) {
                this.getFieldcode(o.codeId, 2)
              }
            })
          }
        }

        this.listchild1 = []
        if (this.codechildlist1.length == 0) {
          this.child = []
        }
      }
    },
    //行业标签标签
    async getFieldList1(id, index) {
      this.paramcode.codeType = '011'
      this.paramcode.parentId = id
      const res = await getCodeMasterByParenId(this.paramcode)
      if (res.data.resultCode == 200) {
        if (this.paramcode.parentId == '0') {
          this.operaList1 = res.data.data
          this.operaListchild1 = []
        } else {
          // this.list1 = this.list1.concat(res.data.data)
          // this.operaListchild1 = this.list1
          if (index == 2) {
            this.operaListchild1 = this.operaListchild1.concat(res.data.data)
            this.operaListchild1=this.removal(  this.operaListchild1)
          } else if (index == 1) {
            let namelist = this.operaListchild1.filter(
              (item) => !res.data.data.some((ele) => ele.codeName == item.codeName)
            )
            this.operaListchild1 = namelist
              this.namelist2 = []
          for (var j = 0; j < this.codechildlist1.length; j++) {
            for (var i = 0; i < this.operaListchild1.length; i++) {
              if (this.codechildlist1[j] == this.operaListchild1[i].codeName) {

                this.namelist2.push(this.codechildlist1[j])
              }
            }
          }
          this.codechildlist1=this.namelist2
          //取消三级
          this.list1=[]
          this.child=[]
          this.codechildlist1.forEach((el) => {
            this.operaListchild1.forEach((o) => {
              if (el == o.codeName) {
                this.getFieldcode(o.codeId, 8)
              }
            })
          })
         
          }
        
        }
      }
    },
   
     //去重
     removal(arr) {
            let newArr = [];
            let obj = {};
            for (var i = 0; i < arr.length; i++) {
                if (!obj[arr[i].codeId]) {
                    newArr.push(arr[i])
                    obj[arr[i].codeId] = true
                }
            }
            return newArr


        },
    async getFieldcode(id, index) {
      this.paramcode.codeType = '011'
      this.paramcode.parentId = id
      const res = await getCodeMasterByParenId(this.paramcode)
      if (res.data.resultCode == 200) {
        if (index == 2) {
          //勾选二级
          this.child = this.child.concat(res.data.data)
          //勾选二级取消第三级的回显
          let namelist1 = this.childcode1.filter(
            (item) => !res.data.data.some((ele) => ele.codeName == item)
          )
          this.childcode1=namelist1
        } else if (index == 1) {
          //取消勾选二级
          let namelist = this.child.filter(
            (item) => !res.data.data.some((ele) => ele.codeName == item.codeName)
          )
          this.child = namelist

         
        } else if (index == 9) {
         
        this.list1 =this.list1.concat(res.data.data)
         this.child = this.list1
          //第一次进来回显
          if (this.form.industryTag != null) {
            this.form.industryTag.forEach((el) => {
              this.child.forEach((o) => {
                if (el == o.codeName) {
                  this.childcode1.push(el)
                }
              })
            })
          }
        
          this.childcode1=[...new Set(this.childcode1)]
        }else if(index==8){
          //清楚三级标签的回显
          this.list1 =this.list1.concat(res.data.data)
         this.child = this.list1
       
        }
      }
    },

    //进去城市
    async getByFatherIdt(id) {
      const res = await getByFatherIdt({ provinceCode: id })
      if (res.data.resultCode == 200) {
        this.operatingLists = res.data.data
      }
    },
    //城市
    async incity() {
      const res = await getProvince()
      if (res.data.resultCode == 200) {
        this.operatingList = res.data.data
      }
    },
    citychange() {
      this.operatingList.forEach((o) => {
        if (this.form.province == o.name) {
          this.form.city = ''
          this.getByFatherIdt(o.code)
        }
      })
    },

    //查询秘书长
    async getSecretaryGeneral() {
      const res = await getSecretaryGeneral()
      if (res.data.resultCode == 200) {
        this.generalist = res.data.data
      }
    },
    //关联商会
    async commercelist() {
      const res = await commercelist()
      if (res.data.resultCode == 200) {
        this.comlist = res.data.data
      }
    },

    storeClick(id) {
      this.typeIndex = id
      if (id == 2) {
        //技术标签
        if (this.form.aiTag != null && this.form.aiTag != '') {
          //子标签
          this.form.aiTag.forEach((el) => {
            this.operaListchild3.forEach((o) => {
              if (el == o.codeName) {
                this.codechildlist3.push(el)
              }
            })
          })
        }
        //战略标签
        if (this.form.industrialTag != null && this.form.industrialTag != '') {
          //子标签
          this.codechildlist2 = []
          this.form.industrialTag.forEach((el) => {
            this.operaListchild2.forEach((o) => {
              if (el == o.codeName) {
                this.codechildlist2.push(el)
              }
            })
          })
        }
        //行业
        if (this.form.industryTag != null && this.form.industryTag != '') {
          //二级标签
          this.codechildlist1 = []
          this.form.industryTag.forEach((el) => {
            this.operaListchild1.forEach((o) => {
              if (el == o.codeName) {
                this.codechildlist1.push(el)
              }
            })
          })
          this.codechange1('', 3)
        }
      }
    },
    //获取长地址图片
    uploadOneImgShowDian(val) {
      this.form.sharePictures = val
    },
    /**
     * 删除图
     */
    removeImgDian(val) {
      this.form.sharePictures = ''
    },
    //获取长地址图片
    uploadOneImgShowDians(val) {
      this.form.companyLogo = val
    },
    /**
     * 删除图
     */
    removeImgDians(val) {
      this.form.companyLogo = ''
    },

    /** 提交按钮 */
    submitForm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.form.id == null) {
            addcompany(this.form).then((response) => {
              this.$message.success('保存成功')
              this.$router.go(-1)
            })
          } else {
            let list = [] //行业标签
            this.form.aiTag = [...this.codelist3, ...this.codechildlist3].toString()
            this.form.industrialTag = [...this.codelist2, ...this.codechildlist2].toString()
            list = [...this.codelist1, ...this.codechildlist1]
            this.form.industryTag = [...list, ...this.childcode1].toString()
            editcompany(this.form).then((response) => {
              this.$message.success('保存成功')
              this.$router.go(-1)
            })
          }
        }
      })
    },
    clcans() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .el-checkbox-group {
  font-size: 0;
  width: 95%;
}
::v-deep .label_code .el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 16px;
  color: #333333;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}
::v-deep .label_code .el-checkbox__label {
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-size: 14px;
  color: #333333;
}
::v-deep .el-radio {
  padding: 5px 0px;
  color: #606266;
  font-weight: 500;
  line-height: 1;
  position: relative;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  outline: 0;
  font-size: 14px;
  margin-right: 30px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
::v-deep .essential_information .el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 596px;
  height: 100px;
  font-size: inherit;
  color: #606266;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

::v-deep .essential_information .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 596px;
}
::v-deep .select_inner .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 41px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 256px;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 590px;
}
::v-deep .date_picker .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 30px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 596px;
}
::v-deep .cascsder_input .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 300px;
}
.addenterprise {
  width: 100%;
  background: #ffffff;
  padding: 10px 35px;
  .label_code {
    padding: 30px 0px;
  }
  .child_label {
    background: #f5f6f7;
    padding: 10px 15px;
    display: flex;
    align-items: baseline;
    width: 80%;
  }
  .store_table {
    padding: 20px 0px;
    width: 100%;
    overflow: hidden;
    height: 48px;
    line-height: 48px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #ffffff;

    .store_table_item {
      padding: 0px 20px;
      overflow: hidden;
      line-height: 48px;
      box-sizing: border-box;
      color: #1f2d3d;
      cursor: pointer;
      text-align: center;
      font-weight: 400;
      font-size: 20px;
    }

    .active {
      border-bottom: 2px solid #4e93fb;
      color: #4e93fb;
      background-color: #fff;
    }
  }

  .img_tips {
    position: relative;
    top: -10px;
    color: #6ba1ff;
  }

  .essential_information {
    h4 {
      color: #146aff;
    }
  }

  .label_management {
    padding: 30px 10px;

    .new_page {
      margin: 20px auto;
      width: 100%;
      text-align: center;
    }

    .input-with {
      width: 100%;
      overflow: hidden;
      padding: 20px 0px;

      .input-with-select {
        width: 400px;
        float: right;
      }

      .seach {
        background-color: #448aff;
        color: #fff;
        border-radius: 0;
        border: 1px solid #448aff;
      }
    }
  }
  .dialog-footer {
    display: flex;
    justify-content: center;
  }
}
</style>
